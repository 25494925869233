import './style.scss';

class SliderBar {
  constructor(options = {}) {
    this.options = {
      width: "",
      height: "",
      beforeImg: null,
      afterImg: null,
      line: true,
      lineColor: ""
    };
    this.localData = {
      sliderContainer: null,
      sliderWrap: null,
      beforeWrap: null,
      sliderBtn: null,
      marginX: 0
    };

    this.isPhone = window.hasOwnProperty("ontouchstart");
    this.moveHandler = this.moveHandler.bind(this);

    this.options = { ...this.options, ...options };
    this.el = document.querySelector(options.el);

    this.init(this.el);
  }

  addListener() {
    const sliderBtn = this.localData.sliderBtn;

    if (this.isPhone) {
      sliderBtn.ontouchstart = this.startSlide.bind(this);
      // Bind touchend and touchcancel to the document to handle outside releases
      document.addEventListener("touchend", this.endSlide.bind(this));
      document.addEventListener("touchcancel", this.endSlide.bind(this));
    } else {
      sliderBtn.onmousedown = this.startSlide.bind(this);
      // Bind mouseup to the document to handle outside releases
      document.addEventListener("mouseup", this.endSlide.bind(this));
    }
  }

  startSlide(e) {
    e.preventDefault();

    const sliderWrap = this.localData.sliderWrap;
    const sliderBtn = this.localData.sliderBtn;

    let slideEvent = (e.touches && e.touches[0]) || e;
    this.localData.marginX = slideEvent.pageX - sliderBtn.offsetLeft;
    this.isPhone
      ? sliderWrap.addEventListener("touchmove", this.moveHandler)
      : sliderWrap.addEventListener("mousemove", this.moveHandler);
  }

  endSlide() {
    const sliderWrap = this.localData.sliderWrap;
    this.isPhone
      ? sliderWrap.removeEventListener("touchmove", this.moveHandler)
      : sliderWrap.removeEventListener("mousemove", this.moveHandler);
  }

  moveHandler(e) {
    let marginX = this.localData.marginX;
    let slideEvent = (e.touches && e.touches[0]) || e;
    this.localData.sliderBtn.style.left = slideEvent.pageX - marginX + "px";
    this.localData.beforeWrap.style.width = slideEvent.pageX - marginX + "px";
  }

  init(el) {
    const {
      beforeImg,
      afterImg,
      width,
      height,
      line,
      lineColor
    } = this.options;
    if (!beforeImg || !afterImg) return;

    el.innerHTML = `
      <div class="slider-container">
        <div class="slider-wrap">
          <div class="before-img">
            <img src=${beforeImg} alt="">
          </div>
          <img src=${afterImg} alt="">
          <span class="slider-btn"></span>
        </div>
      </div>
    `;

    this.localData.sliderContainer = el.querySelector(".slider-container");
    this.localData.sliderWrap = el.querySelector(".slider-wrap");
    this.localData.beforeWrap = el.querySelector(".before-img");
    this.localData.sliderBtn = el.querySelector(".slider-btn");

    const {
      sliderContainer,
      sliderBtn
    } = this.localData;

    el.style.cssText = `display: flex;justify-content: center;`;
    sliderContainer.style.cssText = `width: ${width}; height: ${height};`;
    if (!line) sliderBtn.style.background = "none";
    else if (lineColor) sliderBtn.style.background = lineColor;

    this.addListener();
  }
}
export default SliderBar;
